import {
  defineNuxtRouteMiddleware,
  useAuth,
  navigateTo,
  useError,
} from '#imports'

export default defineNuxtRouteMiddleware(async (to) => {
  const { loggedIn, getRedirectURL } = useAuth()
  const error = useError()

  if (!loggedIn.value && error.value?.statusCode !== 500) {
    return navigateTo(
      await getRedirectURL({
        returnURLParams: to,
      }),
      { external: true },
    )
  }
})
